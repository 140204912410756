import { HighlightCardListProps } from "./types";

import HighlightCard, {
  HighlightCardProps,
} from "~/components/molecules/HighlightCard";

export default function HighlightCardList({
  cards,
}: {
  cards: HighlightCardListProps;
}) {
  if (!cards) return null;

  return (
    <section className="my-9">
      <div className="wrapper grid grid-cols-3 items-center justify-center gap-10 lg:gap-6 sm:hidden">
        {cards.map((card: HighlightCardProps, index: number) => (
          <HighlightCard
            key={card.id + index}
            id={card.id}
            title={card.title}
            icon={card.icon}
            link={card.link}
          />
        ))}
      </div>

      <div className="hidden w-full pl-6 sm:block">
        <div className="scroll-padding-6 no-scrollbar -ml-6 grid snap-x snap-mandatory snap-always grid-flow-col grid-cols-none gap-6 overflow-x-auto px-6 py-0">
          {cards.map((card: HighlightCardProps, index: number) => (
            <HighlightCard
              key={card.id + index}
              id={card.id}
              title={card.title}
              icon={card.icon}
              link={card.link}
              className="sm:scroll-snap-start sm:min-w-[200px]"
            />
          ))}
        </div>
      </div>
    </section>
  );
}
