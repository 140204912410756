import { HighlightCardListProps } from "~/components/organisms/HighlightCardList/types";
import { EIcon } from "~/utilities/enums/Icons";
import { Slug } from "~/utilities/enums/Slug";

export const HIGHLIGHT_NAVIGATOR: HighlightCardListProps = [
  {
    id: "latest-promotion",
    title: "highlight_navigator.latest_promotion",
    icon: { TypeIcon: EIcon.PromoCodeIcon },
    link: { to: Slug.LATEST_PROMOTIONS },
  },
  {
    id: "upcoming-launches",
    title: "highlight_navigator.upcoming_launches",
    icon: { TypeIcon: EIcon.BellIcon },
    link: { to: Slug.UPCOMING_LAUNCHES },
  },
  {
    id: "just-top-top-soon",
    title: "highlight_navigator.just_top_top_soon",
    icon: { TypeIcon: EIcon.LocationIcon },
    link: { to: Slug.TOP_TOP_SOON },
  },
];

// export type IHighlightNavigation = {
//     id: string;
//     title: string;
//     icon: IconProps;
//     slug: string;
// }

// export const HIGHLIGHT_NAVIGATOR: IHighlightNavigation[] = [
//     {
//         id: 'latest-promotion',
//         title: 'highlight_navigator.latest_promotion',
//         icon: { TypeIcon: EIcon.PromoCodeIcon },
//         slug: Slug.LATEST_PROMOTIONS
//     },
//     {
//         id: 'upcoming-launches',
//         title: 'highlight_navigator.upcoming_launches',
//         icon: { TypeIcon: EIcon.BellIcon },
//         slug: Slug.LATEST_PROMOTIONS
//     },
//     {
//         id: 'just-top-top-soon',
//         title: 'highlight_navigator.just_top_top_soon',
//         icon: { TypeIcon: EIcon.LocationIcon },
//         slug: Slug.TOP_TOP_SOON
//     }
// ]
