import { Link, LinkProps } from "@remix-run/react";
import { useTranslation } from "react-i18next";

import { Icon } from "~/components/atoms/Icons";
import { IconProps } from "~/components/atoms/Icons/types";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface HighlightCardProps {
  id: string;
  title: string;
  icon: IconProps;
  link: LinkProps;
  className?: string;
}

const HighlightCard: React.FC<HighlightCardProps> = ({
  id,
  link,
  icon,
  title,
  className = "",
}) => {
  const { t } = useTranslation();
  return (
    <Link
      id={id}
      {...link}
      className={cn(
        "col-span-1 flex min-w-[200px] items-center justify-center gap-12 rounded-3xl bg-highlightBackground py-6 transition-shadow duration-200 hover:shadow-card-highlight-hover xxl:gap-6 lg:flex-col lg:gap-3 lg:py-3 lg:shadow-none lg:hover:shadow-none",
        className
      )}
    >
      <Icon {...icon} className="h-12 w-12 lg:h-8 lg:w-8" ref={null} />
      <Typo
        className="whitespace-no-wrap whitespace-pre-wrap font-bold lg:whitespace-normal lg:text-sub-title-16"
        variant={ETypoVariant.HEADER_24}
        tag={ETypoTag.H2}
        color={ETypoColor.CARD_HIGHLIGHTS}
      >
        {t(title)}
      </Typo>
    </Link>
  );
};

export default HighlightCard;
